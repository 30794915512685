import { graphql, useStaticQuery } from "gatsby";

import { QueryFragments } from "./queryFragments"; // eslint-disable-line

export const useCareerQuery = () => {
  const { allSanityCareer } = useStaticQuery(graphql`
    query {
      allSanityCareer {
        nodes {
          opportunity
          slug {
            current
          }
        }
      }
    }
  `);
  return allSanityCareer || {};
};
