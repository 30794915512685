import React from 'react';
import { motion, AnimatePresence } from 'framer-motion';

// Hooks
import { useScrollFreeze } from '../../hooks';

const ModalInner = ({ children, ...props }) => {
  useScrollFreeze();
  return (
    <motion.div
      {...props}
      className="h-full w-screen top-0 left-0 z-2500 fixed overflow-y-auto bg-black text-logic-navy bg-opacity-80"
    >
      {children}
    </motion.div>
  );
};

export const StandardModal = ({ isOpen, children }) => {
  return (
    <AnimatePresence>
      {isOpen && (
        <ModalInner initial={{ opacity: 0 }} animate={{ opacity: 1 }} exit={{ opacity: 0 }}>
          {children}
        </ModalInner>
      )}
    </AnimatePresence>
  );
};
