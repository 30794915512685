import React from "react";
import { useLocation } from "@reach/router";
import { Formik, Form, Field, ErrorMessage } from "formik";
// import { PortableTextBlock } from '../sanity/portableTextBlock';

function encode(data) {
  return Object.keys(data)
    .map((key) => encodeURIComponent(key) + "=" + encodeURIComponent(data[key]))
    .join("&");
}

export const CareerForm = ({
  className,
  formName,
  submitted,
  setSubmitted,
  setModalOpen,
}) => {
  const { pathname } = useLocation();
  const [message, setMessage] = React.useState(null);

  const itemWrapperClasses = "relative col-span-1";

  const inputClasses =
    "block  rounded shadow-none w-full my-2 border-none p-4 outline-remove bg-logic-input text-white placeholder-white placeholder-opacity-50";

  const errorClasses = "tracking-wide text-sm my-2 text-left text-white";

  //   const onSubmitFile = (values) => {
  //     debugger;
  //   };

  const handleSubmit = (e) => {
    e.preventDefault();

    fetch("/", {
      method: "POST",
      // headers: { "Content-Type": "application/x-www-form-urlencoded" },
      body: new FormData(e.target),
    })
      .then(() => console.log("Form successfully submitted"))
      .then(() => setSubmitted(true))
      .catch((error) => alert(error));
  };

  return (
    <>
      {!submitted && (
        <form
          id="form"
          name={formName}
          enctype="multipart/form-data"
          data-netlify="true"
          className="mb-16"
          onSubmit={(e) => handleSubmit(e)}
        >
          <input type="hidden" name="form-name" value={formName} />
          <p className="text-white">
            <label>
              <input
                name="name"
                type="text"
                className={inputClasses}
                placeholder="Full name"
                required
              />
            </label>
          </p>
          <p className="text-white">
            <label>
              <input
                name="email"
                type="email"
                className={inputClasses}
                placeholder="Email address"
                required
              />
            </label>
          </p>
          <p className="text-white">
            <label>
              <input
                name="phone"
                type="tel"
                className={inputClasses}
                placeholder="Phone number"
                required
              />
            </label>
          </p>
          <p className="text-white">
            <label>
              <textarea
                name="message"
                type="tel"
                className={inputClasses}
                placeholder="Your message"
                rows="5"
                required
              ></textarea>
            </label>
          </p>
          <div className="flex flex-col xl:flex-row justify-between mt-10 items-center">
            <div className="lg:flex items-center self-start">
              <span className="text-white inline-block w-28 mb-4 lg:mb-0">
                File Upload:
              </span>
              <label
                for="file"
                className="block w-38 lg:w-1/2 text-white text-opacity-80 hover:text-opacity-100 mr-4 py-2 px-7 rounded-md  text-sm font-semibold bg-logic-navy  relative  "
              >
                <input
                  name="file"
                  type="file"
                  className="opacity-0 w-full h-full"
                  onChange={(e) => setMessage(e.target.files[0].name)}
                  required
                />
                <div className="absolute top-0 left-0 w-1/2 h-full text-white text-opacity-80 hover:text-opacity-100 mr-4 py-2 px-7 rounded-md border border-opacity-50 border-white border-solid text-sm font-semibold bg-logic-navy pointer-events-none ">
                  Select file
                </div>
              </label>
            </div>
            <button
              className="outline-btn-white mt-6 xl:mt-0 ext-xls:mt-0 self-start"
              type="submit"
            >
              Submit
            </button>
          </div>

          {message && (
            <div className="text-white mt-4">Selected file: {message}</div>
          )}
        </form>
      )}
    </>
  );
};
