import React, { useState } from "react";
import { Link } from "gatsby";
import { useCareerQuery } from "../../../GraphQl/useCareerQuery";
import { useSiteConfig } from "../../../GraphQl/useSiteConfig";

import { BlockWrapper } from "../blockWrapper";
import { PortableTextBlock } from "../../sanity/portableTextBlock";
import { StandardModal } from "../../ui/standardModal";
import { Application } from '../../forms/application'

function CareerBlock({ blockConfig, slug }) {
  const { nodes } = useCareerQuery();
  const { careerHeader, careerFormText } = useSiteConfig();

  //filter out the slug from nodes
  const filteredNodes = nodes.filter(node => node.slug.current !== slug);

  // General Application Popup
  const [modalOpen, setModalOpen] = useState(
    typeof window === "undefined" ? true : false
  );

  return (
    <>
      <BlockWrapper {...blockConfig}>
        <div className="bg-logic-navy text-white py-24 md:py-36">
          <div className="lg:grid grid-cols-14 px-gutter lg:px-0">
            <PortableTextBlock
              text={careerHeader}
              className="prose prose md:prose-lg max-w-none text-center col-start-5 col-end-11 mb-12 md:mb-20"
            />
          </div>
          <div>
            <div className="grid grid-cols-14">
              {filteredNodes?.map(
                (career, index) =>
                  <div key={index} className="mt-10 col-start-2 col-end-14 md:col-start-3 md:col-end-13">
                    <div className="flex flex-col md:flex-row gap-y-2 gap-x-8 md:items-center justify-between">
                      <h1 className="prose prose-lg md:prose-xl">
                        {career?.opportunity}
                      </h1>
                      <Link
                        to={`/careers/${career?.slug?.current}`}
                        className="prose prose-lg md:prose-xl underline"
                      >
                        View Opportunity
                      </Link>
                    </div>
                    <div className="bg-white mt-6 h-px w-full opacity-20" />
                  </div>
              )}

              {/* General Application */}
              <div className="mt-10 col-start-2 col-end-14 md:col-start-3 md:col-end-13">
                <div className="flex flex-col md:flex-row gap-y-2 gap-x-8 md:items-center justify-between">
                  <h1 className="prose prose-lg md:prose-xl">
                    General Application
                  </h1>
                  <button onClick={() => setModalOpen(true)}
                    className="prose prose-lg md:prose-xl underline w-max"
                  >
                    View Opportunity
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </BlockWrapper>

      <StandardModal isOpen={modalOpen}>
        {modalOpen && (
          <Application
            careerFormText={careerFormText}
            setModalOpen={setModalOpen}
            formName="General Application"
          />
        )}
      </StandardModal>
    </>
  );
}

export default CareerBlock;
