import React from "react";

import { CareerForm } from "./careerForm";

import Cross from "../../svg/cross.svg";

export const Application = ({ careerFormText, setModalOpen, formName }) => {
  const [submitted, setSubmitted] = React.useState(false);

  return (
    <>
      {!submitted && (
        <div className="bg-logic-navy px-gutter lg:px-0 h-screen overflow-scroll lg:grid grid-cols-14 grid-rows-2 pt-20">
          <h1 className="text-white col-start-2 col-end-14 text-4xl md:text-5xl mb-6">
            Apply now
          </h1>
          <Cross
            className="col-start-14 col-end-15 cursor-pointer transform -translate-y-10 absolute top-20 right-10"
            onClick={() => setModalOpen(false)}
          />
          <p className="text-white col-start-2 col-end-6 text-base md:text-xl ">
            {careerFormText}
          </p>
          <div className="col-start-7 col-end-14 mt-8 lg:mt-0">
            {" "}
            <CareerForm
              formName={formName}
              submitted={submitted}
              setSubmitted={setSubmitted}
            />
          </div>
        </div>
      )}
      {submitted && (
        <div className="bg-logic-navy px-gutter  h-screen overflow-scroll  pt-20 flex">
          <Cross
            className=" cursor-pointer transform -translate-y-10 absolute top-20 right-10"
            onClick={() => setModalOpen(false)}
          />
          <div className="text-center w-1/2 mx-auto self-center">
            <h1 className="text-white text-4xl md:text-5xl mb-6">Thank you</h1>

            <p className="text-white text-base md:text-3xl">
              We will review your submission and be in touch. Please email careers@logiqgroup.nz if you do not hear back from us.
            </p>
            <button
              className="outline-btn-white mt-6 xl:mt-11 ext-xls:mt-0 self-start"
              onClick={() => setModalOpen(false)}
            >
              Close
            </button>
          </div>
        </div>
      )}
    </>
  );
};
