import React, { useEffect } from 'react';

import { useAppContext } from '../../state';
import { useInView } from 'react-intersection-observer';
// Hooks
import { usePadTop, usePadBottom } from '../../hooks';

export const BlockWrapper = ({
  children,
  className,
  paddingTop,
  paddingBottom,
  toggleTheme,
  slug,
}) => {
  const padTop = usePadTop(paddingTop);
  const padBottom = usePadBottom(paddingBottom);

  const { reverseNav, setReverseNav } = useAppContext();
  const { ref, inView, entry } = useInView({
    /* Optional options */
    threshold: 0,
  });

  useEffect(() => {
    if (inView) {
      if (toggleTheme === 'themeDark') {
        document.documentElement.classList.add('themeDark');
        // setReverseNav(true);
      } else if (toggleTheme === 'themeLight') {
        document.documentElement.classList.remove('themeDark');
        // setReverseNav(false);
      }
    }
  }, [reverseNav, setReverseNav, inView, toggleTheme]);

  return (
    <section className={`${className} ${padTop} ${padBottom}`} id={slug && slug.current} ref={ref}>
      {children}
    </section>
  );
};
